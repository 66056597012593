import React, { useState, useEffect, useContext } from 'react';
import { Alert, message, Select, Table } from 'antd';
import _ from 'lodash';
import ReactGA from 'react-ga4';
import style from './style.module.scss';
import FileAction from './FileAction';
import ResizableColumn from '../../../../components/ResizableColumn';
import ReportTemplateGetHeader from './ReportTemplateGetHeader';
import ReportTemplateGetBody from './ReportTemplateGetBody';
import OrgContext from '../../../../context/OrgContext';
import {
  getIntegrationTypeById,
  getReportBodies,
  getReportBodyInfo,
} from '../../../../service/reportServices';

const { Option } = Select;

const ReportTemplate = ({
  selectedReportForm,
  reportFormDatas,
  orgRecurring,
}) => {
  const { orgInfo } = useContext(OrgContext);

  const bankAccounts = orgInfo?.orgBankAccounts || [];
  const [loading, setLoading] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [treeDataSource, setTreeDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [integrationState, setIntegrationState] = useState({
    code: null,
    isVisibleChooseAccount: false,
    checkedAccounts: [],
    filterAccount: null,
    accountCol: null,
  });

  const [selectedReport, setSelectedReport] = useState(
    reportFormDatas.length > 0 && reportFormDatas[0].id
  );
  const [reportFormData, setReportFormData] = useState(
    reportFormDatas.length > 0 ? reportFormDatas[0] : {}
  );

  const pageSize = 1000;
  const [bodyInfo, setBodyInfo] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const isIntegrationByAccount = [
    'integrationECopyDebits',
    'integrationECopyCredits',
    'integrationECopyBudgets',
    'integrationECopyPerformanceConsolidation',
    'integrationECopyBudgetHzs',
  ].includes(integrationState.code);

  const integrationParentChild = integrationDatas => {
    if (integrationDatas.find(integration => integration.parentId)) {
      const treeData = [];
      let temp = {};
      [...integrationDatas].reverse().forEach(integ => {
        if (integ?.parentId && integ?.parentId === temp?.recordId) {
          temp?.children?.unshift(integ);
        } else if (integ?.recordId && integ?.recordId === temp?.recordId) {
          if (integ?.parentId) {
            temp = {
              recordId: integ?.parentId,
              children: [
                {
                  ...integ,
                  children: temp.children || [],
                },
              ],
            };
          } else {
            treeData.unshift({
              ...integ,
              children: temp.children || [],
            });
            temp = {};
          }
        } else if (_.isEmpty(temp)) {
          if (integ?.parentId) {
            temp = {
              recordId: integ?.parentId,
              children: [integ],
            };
          } else {
            treeData.unshift(integ);
          }
        } else {
          const ind = treeData.findIndex(fnd => fnd.recordId === temp.recordId);
          if (ind === -1) {
            treeData.unshift(temp);
          } else if (treeData[ind].children) {
            treeData[ind].children.unshift(...temp.children);
          } else {
            treeData[ind].children = temp.children;
          }
          temp = {};
        }
      });
      setTreeDataSource(treeData);
    } else setTreeDataSource([]);
  };

  const setDatas = data => {
    setDataSource(data || []);
    integrationParentChild(data || []);
    if (isIntegrationByAccount) {
      const accountList = _.uniq(
        data.map(item => item[integrationState.accountCol])
      );
      setIntegrationState({
        ...integrationState,
        isVisibleChooseAccount: false,
        checkedAccounts: accountList,
      });
    }
  };

  const filterByAccountNumber = () => {
    if (integrationState.accountCol) {
      const data = dataSource.filter(
        item =>
          `${item[integrationState.accountCol]}` ===
          `${integrationState.filterAccount}`
      );
      setFilteredDataSource(data);
    }
  };

  const onChangePage = num => {
    setCurrentPage(num);
  };

  const getBodyInfo = id => {
    setLoading(true);
    setDatas([]);
    setCurrentPage();
    getReportBodyInfo({
      id,
      size: pageSize,
    })
      .then(res => {
        setBodyInfo(res);
        setCurrentPage(1);
      })
      .finally(() => setLoading(false));
  };

  const init = () => {
    setDatas([]);
    setColumns(ReportTemplateGetHeader(selectedReportForm, orgRecurring));

    if (selectedReportForm.integrationTypeId) {
      const getIntegrationType = async () => {
        const integrationType = await getIntegrationTypeById(
          selectedReportForm.integrationTypeId
        );
        if (!integrationType) {
          message.warn('Интеграцийн төрөл олдсонгүй!');
        } else {
          let integrationAccountField = null;
          if (
            integrationType.code === 'integrationECopyDebits' ||
            integrationType.code === 'integrationECopyBudgets'
          ) {
            integrationAccountField = 'account';
          } else if (integrationType.code === 'integrationECopyCredits') {
            integrationAccountField = 'accountNumber';
          }
          const findAccountNumberHeader = integrationAccountField
            ? selectedReportForm.reportFormHeaders.find(
                header => header.integrationField === integrationAccountField
              )
            : selectedReportForm.reportFormHeaders.find(header =>
                (header.data || '').toLowerCase().includes('данс')
              );
          setIntegrationState({
            ...integrationState,
            code: integrationType.code,
            accountCol: findAccountNumberHeader
              ? findAccountNumberHeader.col
              : null,
          });
        }
      };
      getIntegrationType();
    }
  };

  useEffect(() => {
    if (selectedReport) {
      getBodyInfo(selectedReport);
    }
  }, [selectedReport]);

  useEffect(() => {
    if (bodyInfo && currentPage) {
      if (bodyInfo?.pages?.length > currentPage - 1) {
        const page = bodyInfo.pages[currentPage - 1];
        const params = {
          reportFormOrgRecurringId: reportFormData.orgRecurringId,
          id: reportFormData.id,
          beginLine: page.beginLine,
          endLine: page.endLine,
        };

        setLoading(true);
        getReportBodies(params)
          .then(res => {
            setDatas(
              ReportTemplateGetBody(
                selectedReportForm,
                res || [],
                (currentPage - 1) * pageSize
              )
            );
            setDatas(
              ReportTemplateGetBody(
                selectedReportForm,
                res || [],
                (currentPage - 1) * pageSize
              )
            );
          })
          .finally(() => setLoading(false));
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (integrationState.filterAccount) {
      filterByAccountNumber();
    } else {
      setFilteredDataSource(dataSource);
    }
  }, [dataSource, integrationState.filterAccount]);

  useEffect(() => {
    init();

    if (selectedReportForm) {
      ReactGA.event({
        action: 'view_report',
        category: 'report',
        label: selectedReportForm?.name,
        value: selectedReportForm?.id,
      });
    }
  }, [selectedReportForm]);

  const components = {
    header: {
      cell: ResizableColumn,
    },
  };

  const allColumn = cols =>
    cols
      .map(col => [col.children ? allColumn(col.children) : [], col])
      .flat(Infinity);

  const handleResize =
    resizeColKey =>
    (e, { size }) => {
      const findCols = allColumn(columns).filter(
        col => col.resizeColKey === resizeColKey
      );
      if (!_.isEmpty(findCols)) {
        findCols.forEach(col => {
          col.width = size.width;
        });
        setColumns([...columns]);
      }
    };

  const getColumns = fields =>
    fields.map(col => {
      const tmpCol = {
        ...col,
        onHeaderCell: column => ({
          width: column.width,
          onResize: handleResize(column.resizeColKey),
        }),
      };
      if (!_.isEmpty(tmpCol.children)) {
        tmpCol.children = getColumns(tmpCol.children);
      }
      return tmpCol;
    });

  const cols = getColumns(columns);

  return (
    <>
      <div className={style.tableActions}>
        <div style={{ marginRight: '15px', marginTop: '12px' }}>
          <b>Мэдээлэл оруулсан огноо:</b>
        </div>
        <Select
          value={selectedReport}
          style={{
            width: 200,
            marginTop: '7px',
          }}
          onChange={e => {
            setSelectedReport(e);
          }}
        >
          {reportFormDatas.map(report => (
            <Option value={report.id}>
              {report?.createdAt?.length > 19
                ? report.createdAt.substring(0, 19)
                : report.createdAt}
            </Option>
          ))}
        </Select>
        {!reportFormData?.isEmptyReport && (
          <FileAction
            selectedReportForm={selectedReportForm}
            columns={columns}
            dataSource={dataSource}
          />
        )}
      </div>
      {reportFormData?.isEmptyReport ? (
        <Alert
          message="Мэдээлэл байхгүй."
          description={reportFormData?.description ?? ''}
          type="info"
          showIcon
        />
      ) : (
        <>
          {isIntegrationByAccount &&
            !_.isEmpty(integrationState.checkedAccounts) && (
              <div style={{ marginBottom: 8 }}>
                Дансны дугаараар шүүж харах:{' '}
                <Select
                  size="small"
                  style={{ minWidth: 300 }}
                  allowClear
                  value={integrationState.filterAccount}
                  onChange={value =>
                    setIntegrationState({
                      ...integrationState,
                      filterAccount: value,
                    })
                  }
                >
                  {(integrationState.checkedAccounts || []).map(account => {
                    const bancAcc = bankAccounts.find(
                      bank => `${bank.accountNumber}` === `${account}`
                    );
                    return (
                      <Option key={account}>
                        {bancAcc ? `${account} - ${bancAcc.name}` : account}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            )}
          <div className={style.tableWrapper}>
            <Table
              rowKey={record => record?.line}
              bordered
              size="small"
              loading={loading}
              dataSource={
                treeDataSource.length > 0 ? treeDataSource : filteredDataSource
              }
              columns={cols}
              components={components}
              pagination={{
                total: bodyInfo.lineCount || 0,
                pageSize,
                current: currentPage,
                onChange: onChangePage,
                showSizeChanger: false,
              }}
              scroll={{
                x: '100%',
                y: selectedReportForm.isHaveFile
                  ? 'calc(100vh - 260px)'
                  : 'auto',
              }}
              summary={() => (
                <Table.Summary fixed="bottom">
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={columns.length}>
                      <h4 style={{ padding: 8 }}>
                        Нийт: {bodyInfo.lineCount || 0}
                      </h4>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
              sticky
            />
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(ReportTemplate);

