import React, { useState } from 'react';

import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import OrganizationAbout from './OrganizationAbout';
import OrganizationResult from './OrganizationResult';
import OrganizationBudget from './OrganizationBudget';
import ReportOrganizationData from './report/ReportOrganizationData';
import { OrgStore } from '../../context/OrgContext';
import { CONTENT_SPAN } from '../../constants/SystemConstants';
import NotFound from '../notFound/NotFound';

export default function Organization() {
  const { orgId } = useParams();
  const [hideInfo, setHideInfo] = useState();

  if (!Number(orgId)) {
    return <NotFound />;
  }

  return (
    <Row justify="center">
      <Col {...CONTENT_SPAN}>
        <OrgStore>
          <OrganizationAbout />
          <ReportOrganizationData setHideInfo={setHideInfo} />
          {hideInfo && (
            <>
              <OrganizationResult />
              <OrganizationBudget />
            </>
          )}
        </OrgStore>
      </Col>
    </Row>
  );
}
