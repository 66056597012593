/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
// import time from '../../../img/IconIonicMdTime.svg';
import { getNews } from '../../../service/portalService';
import { SORT_ORDER } from '../../../constants/Constant';
import { getImgSrc } from '../../../tools/Tools';
import { PORTAL_NEWS_DETAIL } from '../../../settings/constantRoutes';

export default function Informarioin() {
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [InformationData, setInformationData] = useState([]);
  const [selections] = useState(0);
  const slideCount = Math.ceil(window.innerWidth / 500);
  const slider = React.useRef(null);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const params = {
      search: { isActive: true, status: true },
      sortField: 'priority',
      sortOrder: SORT_ORDER.DESC,
      // size: 4,
    };
    getNews(params).then(data => {
      const linkData = data.content || [];
      setInformationData(linkData);
    });
  }, []);

  const settings = {
    autoplay: true,
    speed: 500,
    centerMode: true,
    dots: true,
    arrows: false,
    infinite: true,
    swipe: false,
    slidesToShow: slideCount > 4 ? 4 : slideCount,
    slidesToScroll: 1,
    className: styles.slider,
  };

  return (
    <>
      <Modal
        title={<span style={{ fontSize: 20 }}>{selections?.description}</span>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        width={850}
      >
        <Row justify="start" gutter={13}>
          <Col span={10}>
            <img
              src={getImgSrc(selections?.imgUuid)}
              alt="news"
              style={{ width: '100%' }}
            />
          </Col>
          <Col justifyContent="center" span={13}>
            <div className={styles.bodyText}>
              <span>{selections?.content}</span>
            </div>
          </Col>
        </Row>
      </Modal>
      <div className="main-title">
        <span>Мэдээ, мэдээлэл</span>
      </div>

      <Row align="middle">
        <Col span={1} align="left">
          <Button
            type="primary"
            shape="circle"
            icon={<ArrowLeftOutlined />}
            onClick={() => slider?.current?.slickPrev()}
          />
        </Col>
        <Col span={22}>
          <Slider ref={slider} {...settings}>
            {InformationData.map(item => (
              <div className={styles.allCard}>
                <div className={styles.smallNewsParent}>
                  <div
                    style={{
                      backgroundImage: `url(${getImgSrc(item?.imgUuid)})`,
                    }}
                    className={styles.smallNews}
                    role="main"
                    onClick={() => {
                      navigate(`${PORTAL_NEWS_DETAIL}/${item.id}`);
                    }}
                  >
                    <div className={styles.newsTitle}>
                      <div className={styles.newsDesc}>{item?.description}</div>
                      {/* <div className={styles.more}>
                  <div>
                    <img src={time} alt="thumpsUp" /> 10 цагийн өмнө
                  </div>
                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Col>
        <Col span={1} align="right">
          <Button
            type="primary"
            shape="circle"
            icon={<ArrowRightOutlined />}
            onClick={() => slider?.current?.slickNext()}
          />
        </Col>
      </Row>
    </>
  );
}
