import React from 'react';
import { Card, Image, Skeleton, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import { ORGANIZATION_PAGE } from '../../settings/constantRoutes';
import suld from '../../img/suld.svg';
import styles from './style.module.scss';
import { getImgSrc } from '../../tools/Tools';

export default function OrgCard({ org }) {
  const goToOrg = () => {
    window.open(`${ORGANIZATION_PAGE}/${org.id}`);
  };

  if (!org.id) {
    return <Skeleton.Input active block className={styles.loader} />;
  }

  return (
    <Card className={styles.contentCard} onClick={goToOrg} hoverable>
      <Space size={16}>
        <div className={styles.imgDiv}>
          <Image
            key={org.id}
            src={org.logoUuid ? getImgSrc(org?.logoUuid) : suld}
            fallback={suld}
            preview={false}
          />
        </div>
        <Text strong>{org.name}</Text>
      </Space>
    </Card>
  );
}

