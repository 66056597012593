import { message } from 'antd';
import axios from 'axios';
import Axios from './Axios';
// import { errorCatch } from '../tools/Tools';
import { getParamsQueryString } from './utils';
import mockDatas from '../mock-datas';

let ipStat = null;

export async function getIPAddress() {
  if (!ipStat) {
    ipStat = 'loading';
    Axios.get('https://geolocation-db.com/json/')
      .then(res => {
        localStorage.setItem('addr', res.data.IPv4);
        ipStat = 'done';
      })
      .catch(() => {
        localStorage.setItem('addr', 'warn');
        ipStat = 'warn';
      });
  }
}

const getConfig = (cancelRef, config) => {
  const addr = localStorage.getItem('addr');
  if (!addr) {
    getIPAddress();
  }

  const con = { ...config };
  if (con.headers && addr) {
    con.headers.address = addr;
  } else if (addr) {
    con.headers = {
      address: addr,
    };
  }

  let cancelToken;
  if (cancelRef) {
    if (cancelRef.current) {
      cancelRef.current.cancel();
    }
    cancelRef.current = axios.CancelToken.source();
    cancelToken = cancelRef.current?.token;
  }
  return { cancelToken, ...con };
};

export async function getRequest(
  serviceName,
  queryParams,
  cancelRef,
  config = null
) {
  const params = getParamsQueryString(queryParams);
  let response = null;
  try {
    response = await Axios.get(
      serviceName + params,
      getConfig(cancelRef, config)
    );
  } catch (error) {
    // errorCatch(error, serviceName);
    if (process.env.NODE_ENV === 'development' && mockDatas[serviceName]) {
      return mockDatas[serviceName];
    }
    throw error;
  }
  return response?.data;
}

export async function downloadBlob(serviceName) {
  const response = await Axios.get(serviceName, { responseType: 'blob' }).catch(
    error => {
      // errorCatch(error, serviceName);
      throw error;
    }
  );
  return response?.data;
}

export async function postService(serviceName, data, queryParams, cancelRef) {
  const params = getParamsQueryString(queryParams);
  const response = await Axios.post(
    serviceName + params,
    data,
    getConfig(cancelRef)
  ).catch(error => {
    // errorCatch(error, serviceName);
    throw error;
  });
  if (response.status !== 200) {
    message.warning('АМЖИЛТГҮЙ:', response.statusText);
  }
  return response.data;
}

export async function putService(serviceName, data = null) {
  const response = await Axios.put(serviceName, data).catch(error => {
    // errorCatch(error, serviceName);
    throw error;
  });
  if (response.status !== 200) {
    message.warning('АМЖИЛТГҮЙ:', response.statusText);
  }
  return response.data;
}
